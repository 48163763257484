import React from 'react';
import { Button } from "./ui/button"
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card"
import { Sparkles, Image, Users, ThumbsUp, Mail, BarChart } from 'lucide-react';

interface ExamplePrompt {
  label: string;
  text: string;
  icon: React.ReactNode;
}

interface ExamplePromptsCardProps {
  onSelectPrompt: (text: string) => void;
}

const examplePrompts: ExamplePrompt[] = [
  { label: "Create marketing plan", text: "Create a comprehensive marketing plan for my product. My budget is $1000.", icon: <Sparkles className="w-4 h-4 mr-2" /> },
  { label: "Create image for ad", text: "Generate an image for an advertisement featuring my product", icon: <Image className="w-4 h-4 mr-2" /> },
  { label: "Competitor analysis", text: "What are my competitors doing in the market?", icon: <Users className="w-4 h-4 mr-2" /> },
  { label: "Customer perspective", text: "What would my customers think if we introduced this new feature?", icon: <ThumbsUp className="w-4 h-4 mr-2" /> },
  { label: "Write newsletter", text: "Write a newsletter for my product launch", icon: <Mail className="w-4 h-4 mr-2" /> },
  { label: "Analyse my ad", text: "Analyze the effectiveness of my current ad.", icon: <BarChart className="w-4 h-4 mr-2" /> },
];

const ExamplePromptsCard: React.FC<ExamplePromptsCardProps> = ({ onSelectPrompt }) => {
  return (
    <Card className="w-full mb-4 bg-black text-white border-gray-700">
      <CardHeader>
        <CardTitle className="text-lg text-muted-foreground">Try these example prompts:</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-2">
          {examplePrompts.map((prompt, index) => (
            <Button
              key={index}
              variant="outline"
              className="text-sm bg-black hover:bg-gray-900 text-white border-gray-700 flex items-center justify-start px-3 py-2"
              onClick={() => onSelectPrompt(prompt.text)}
            >
              {prompt.icon}
              {prompt.label}
            </Button>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default ExamplePromptsCard;