import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import HomePage from './components/HomePage';
import './index.css';
import ChatPage from './components/ChatPage';
import SettingsPage from './components/SettingsPage';
import VisualModeSelector from './components/VisualModeSelector';
import { useAuth0 } from '@auth0/auth0-react';
import PricingPage from './components/PricingPage';
import { TooltipProvider } from './components/ui/tooltip';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner';
import { Tool, FollowUpPrompt, ChatResponse } from './types';

type Page = 'home' | 'chat' | 'settings' | 'pricing';

// Use environment variable for API URL, with a fallback for local development
const API_URL = process.env.REACT_APP_API_URL || 'https://adout.ai';

function AppContent() {
  const { isAuthenticated, isLoading: authLoading, user, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [page, setPage] = useState<Page>('home');
  const [description, setDescription] = useState('');
  const [aspectRatio, setAspectRatio] = useState<string>('1:1');
  const [chatMessages, setChatMessages] = useState<string[]>([]);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [localUser, setLocalUser] = useState(user);
  const [latestCampaign, setLatestCampaign] = useState<any | null>(null);
  const [currentBrand, setCurrentBrand] = useState<any>(null);
  const [imageKey, setImageKey] = useState<string | null>(null);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | null>(null);
  const [unauthenticatedChatData, setUnauthenticatedChatData] = useState<{
    messages: string[],
    imageUrl: string | null
  } | null>(null);

  const homePageRef = useRef(null);
  const chatPageRef = useRef(null);
  const settingsPageRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const [allowUnauthenticatedChat, setAllowUnauthenticatedChat] = useState(false);
  const [isAppLoading, setIsAppLoading] = useState(true);

  const [userDataKey, setUserDataKey] = useState<string | null>(null);
  const [followUpPrompts, setFollowUpPrompts] = useState<FollowUpPrompt[]>([]);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    if (!authLoading) {
      setIsAppLoading(false);
    }
  }, [authLoading]);

  useEffect(() => {
    if (!authLoading) {
      if (isAuthenticated && user) {
        createOrUpdateUser(user);
        // Restore chat data for newly authenticated users
        const savedMessages = localStorage.getItem('unauthenticatedChatMessages');
        const savedImageUrl = localStorage.getItem('unauthenticatedGeneratedImageUrl');
        if (savedMessages) {
          setChatMessages(JSON.parse(savedMessages));
          localStorage.removeItem('unauthenticatedChatMessages');
        }
        if (savedImageUrl) {
          setGeneratedImageUrl(savedImageUrl);
          localStorage.removeItem('unauthenticatedGeneratedImageUrl');
        }
        // Only navigate to chat page if we're on the home page
        if (location.pathname === '/') {
          setPage('chat');
          navigate('/chat', { replace: true });
        }
      } else {
        if (location.pathname === '/chat' && !allowUnauthenticatedChat) {
          setPage('home');
          navigate('/', { replace: true });
        }
      }
    }
  }, [authLoading, isAuthenticated, user, navigate, location.pathname, allowUnauthenticatedChat, page, unauthenticatedChatData]);

  const createOrUpdateUser = async (user: any) => {
    try {
      const storedUserDataKey = localStorage.getItem('userDataKey');
      const response = await axios.post(`${API_URL}/api/users`, {
        sub: user.sub,
        name: user.name,
        email: user.email,
        picture: user.picture,
        userDataKey: storedUserDataKey
      });
      setLocalUser({...response.data, auth0Id: user.sub});
      localStorage.removeItem('userDataKey');
    } catch (error) {
      console.error('Error creating/updating user:', error);
    }
  };

  const updateUserNick = async (nick: string) => {
    if (!localUser) return;
    try {
      const response = await axios.put(`${API_URL}/api/users/${localUser.id}`, { nick });
      setLocalUser(prevUser => ({ ...prevUser, nick }));
    } catch (error) {
      console.error('Error updating user nick:', error);
      throw error;
    }
  };

  const updateUserName = async (name: string) => {
    if (!user) return;
    try {
      const response = await axios.put(`${API_URL}/api/users/${user.sub}`, { name });
      setLocalUser(prevUser => ({ ...prevUser, name }));
    } catch (error) {
      console.error('Error updating user name:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (description.trim()) {
      setIsGeneratingImage(true);
      setAllowUnauthenticatedChat(true);
      
      // Navigate to chat page immediately
      setPage('chat');
      navigate('/chat');

      try {
        const response = await fetch(`${API_URL}/api/chat`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            message: description, 
            aspectRatio: aspectRatio
          })
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        
        setChatMessages([`User: ${description}`, `AI: ${data.content}`]);
        if (data.imageUrl) {
          setGeneratedImageUrl(data.imageUrl);
        }
        if (data.imageKey) {
          setImageKey(data.imageKey);
          localStorage.setItem('imageKey', data.imageKey);
        }
        if (data.userDataKey) {
          setUserDataKey(data.userDataKey);
          localStorage.setItem('userDataKey', data.userDataKey);
        }
      } catch (error) {
        console.error('Error submitting chat:', error);
        setChatMessages([`Error: An error occurred while processing your request.`]);
      } finally {
        setIsGeneratingImage(false);
      }
    }
  };

  const handleBackClick = () => {
    setPage('home');
    setChatMessages([]);
    setIsGeneratingImage(false);
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handlePricingClick = () => {
    setShowPricing(true);
  };

  const handleClosePricing = () => {
    setShowPricing(false);
  };

  const handleLogin = () => {
    // Save current chat data for unauthenticated users
    if (!isAuthenticated && chatMessages.length > 0) {
      localStorage.setItem('unauthenticatedChatMessages', JSON.stringify(chatMessages));
      if (generatedImageUrl) {
        localStorage.setItem('unauthenticatedGeneratedImageUrl', generatedImageUrl);
      }
    }
    localStorage.setItem('loginRedirectPath', location.pathname);
    loginWithRedirect();
  };

  const handleSendMessage = async (message: string): Promise<ChatResponse> => {
    setIsGeneratingImage(true);
    // Immediately add the user's message to the chat
    setChatMessages(prevMessages => [...prevMessages, `User: ${message}`]);
    
    try {
      const response = await axios.post(`${API_URL}/api/tool-chat`, {
        messages: [{ role: "user", content: [{ type: "text", text: message }] }],
        authToken: user?.sub,
        imageKey
      });
      console.log('API Response:', response.data);  // Log the entire API response

      const { aiResponse, toolResults, generatedImageUrl, currentImageData, toolUseSummary, campaignInfo, followUpPrompts } = response.data;

      console.log('Campaign Info from API:', campaignInfo);  // Log the campaign info specifically

      // Update chat messages with AI response and other information
      const newMessages = [
        `AI: ${aiResponse}`
      ];

      if (toolUseSummary && toolUseSummary !== "No tools were used in generating this response.") {
        newMessages.push(`Tool Summary: ${toolUseSummary}`);
      }

      if (campaignInfo) {
        console.log('Adding Campaign Info to chat messages:', campaignInfo);  // Log before adding to chat messages
        newMessages.push(`CAMPAIGN_INFO: ${JSON.stringify(campaignInfo)}`);
      }

      // Update chat messages
      setChatMessages(prevMessages => [...prevMessages, ...newMessages]);

      setGeneratedImageUrl(generatedImageUrl);
      setImageKey(currentImageData?.imageKey || null);
      setFollowUpPrompts(followUpPrompts || []);

      return response.data;
    } catch (error) {
      console.error('Error sending message:', error);
      setChatMessages(prevMessages => [...prevMessages, `Error: ${error instanceof Error ? error.message : 'An unknown error occurred'}`]);
      throw error;
    } finally {
      setIsGeneratingImage(false);
    }
  };

  const pollForImage = async (imageKey: string) => {
    const maxAttempts = 30;
    const interval = 2000; // 2 seconds

    for (let i = 0; i < maxAttempts; i++) {
      try {
        const response = await axios.get(`${API_URL}/api/image-status?imageKey=${imageKey}`);
        if (response.data.status === 'completed' && response.data.imageUrl) {
          setGeneratedImageUrl(response.data.imageUrl);
          setIsGeneratingImage(false);
          return;
        }
      } catch (error) {
      }

      await new Promise(resolve => setTimeout(resolve, interval));
    }

    setIsGeneratingImage(false);
  };

  const sendMessage = async (message: string) => {
    setIsGeneratingImage(true);
    try {
      const response = await fetch(`${API_URL}/api/chat`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          message, 
          aspectRatio
        }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();

      setChatMessages(prev => [...prev, `AI: ${data.content}`]);
      if (data.imageKey) {
        setImageKey(data.imageKey);
        pollForImage(data.imageKey);
      } else if (data.imageError) {
        setChatMessages(prev => [...prev, `Error: ${data.imageError}`]);
        setIsGeneratingImage(false);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setChatMessages(prev => [...prev, `Error: ${error instanceof Error ? error.message : 'An unknown error occurred'}`]);
      setIsGeneratingImage(false);
    }
  };

  const sendToolMessage = async (message: string) => {
    try {
      const content = [];

      if (generatedImageUrl) {
        try {
          const response = await fetch(generatedImageUrl);
          const blob = await response.blob();
          const base64data = await new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
          });
          setImageBase64(base64data);

          const [header, base64] = base64data.split(',');
          
          content.push({
            type: "image",
            source: {
              type: "base64",
              media_type: "image/webp",
              data: base64
            }
          });
        } catch (error) {
          console.error('Error converting image to base64:', error);
        }
      }

      content.push({
        type: "text",
        text: message
      });

      const storedImageKey = localStorage.getItem('imageKey');  // Retrieve imageKey from localStorage

      // Get the access token
      const token = await getAccessTokenSilently();

      const response = await axios.post(`${API_URL}/api/tool-chat`, {
        messages: [{ role: "user", content }],
        authToken: user?.sub,
        imageKey: storedImageKey,
        image: imageBase64
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (!authLoading) {
      setAuthChecked(true);
    }
  }, [authLoading]);

  const renderPage = () => {
    if (!authChecked) {
      return <LoadingSpinner />;
    }

    switch (location.pathname) {
      case '/':
        return isAuthenticated ? (
          <Navigate to="/chat" replace />
        ) : (
          <HomePage
            ref={homePageRef}
            description={description}
            setDescription={setDescription}
            aspectRatio={aspectRatio}
            setAspectRatio={setAspectRatio}
            onSubmit={handleSubmit}
            isAuthenticated={isAuthenticated}
            user={localUser}
            loginWithRedirect={loginWithRedirect}
            logout={logout}
            onSettingsClick={handleSettingsClick}
            onPricingClick={handlePricingClick}
          />
        );
      case '/chat':
        return (
          <ChatPage
            ref={chatPageRef}
            initialDescription={description}
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            isLoading={isGeneratingImage}
            setIsLoading={setIsGeneratingImage}
            generatedImageUrl={generatedImageUrl}
            setGeneratedImageUrl={setGeneratedImageUrl}
            isGeneratingImage={isGeneratingImage}
            setIsGeneratingImage={setIsGeneratingImage}
            sendMessage={handleSendMessage}
            isAuthenticated={isAuthenticated}
            user={user}
            loginWithRedirect={handleLogin}
            logout={logout}
            onSettingsClick={handleSettingsClick}
            onPricingClick={handlePricingClick}
            latestCampaign={latestCampaign}
            currentBrand={currentBrand}
            forceUpdate={forceUpdate}
            imageKey={imageKey}
            setImageKey={setImageKey}
            aspectRatio={aspectRatio}
            followUpPrompts={followUpPrompts}
            setFollowUpPrompts={setFollowUpPrompts}
          />
        );
      case '/settings':
        return isAuthenticated ? (
          <SettingsPage
            ref={settingsPageRef}
            onClose={handleCloseSettings}
            onSettingsClick={handleSettingsClick}
            isAuthenticated={isAuthenticated}
            localUser={localUser}
            loginWithRedirect={loginWithRedirect}
            logout={logout}
            onPricingClick={handlePricingClick}
            updateUserName={updateUserName}
            updateUserNick={updateUserNick}
          />
        ) : (
          <Navigate to="/" replace />
        );
      case '/pricing':
        return (
          <PricingPage onClose={handleClosePricing} />
        );
      default:
        return <Navigate to="/" />;
    }
  };

  useEffect(() => {
    const setAuthToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } catch (error) {
        }
      }
    };

    setAuthToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
  }, [latestCampaign]);

  return (
    <TooltipProvider delayDuration={100}>
      <div className="App">
        {renderPage()}
      </div>
    </TooltipProvider>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;