import React from 'react';
import '../styles/VisualModeSelector.css';

export interface VisualModeSelectorProps {
  aspectRatio: string;
  setAspectRatio: React.Dispatch<React.SetStateAction<string>>;
}

const VisualModeSelector: React.FC<VisualModeSelectorProps> = ({ aspectRatio, setAspectRatio }) => {
  return (
    <div className="visual-mode-selector">
      <button
        className={`mode-button ${aspectRatio === '1:1' ? 'active' : ''}`}
        onClick={() => setAspectRatio('1:1')}
      >
        Square
      </button>
      <button
        className={`mode-button ${aspectRatio === '16:9' ? 'active' : ''}`}
        onClick={() => setAspectRatio('16:9')}
      >
        Landscape
      </button>
      <button
        className={`mode-button ${aspectRatio === '9:16' ? 'active' : ''}`}
        onClick={() => setAspectRatio('9:16')}
      >
        Vertical
      </button>
    </div>
  );
};

export default VisualModeSelector;