import React, { useState, useEffect, useMemo } from 'react';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "./ui/hover-card";
import { useBrand, Brand } from '../hooks/useBrand';
import { useProduct, Product } from '../hooks/useProduct';
import { useCampaign, Campaign } from '../hooks/useCampaign';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

interface CampaignInfoProps {
  campaignInfo: string;
  latestCampaign: Campaign | null;
  currentBrand: Brand | null;
  setChatMessages: React.Dispatch<React.SetStateAction<string[]>>;
  onSettingsClick: () => void; // Add this prop
}

interface EditedData extends Partial<Campaign> {
  brandName: string;
  productName: string;
}

const CampaignInfo: React.FC<CampaignInfoProps> = ({
  campaignInfo,
  latestCampaign,
  currentBrand,
  setChatMessages,
  onSettingsClick, // Add this prop
}) => {
  const [editedData, setEditedData] = useState<EditedData | null>(null);
  const navigate = useNavigate(); // Use the useNavigate hook

  const { selectedBrand } = useBrand(currentBrand);
  const { selectedProduct } = useProduct(selectedBrand?.id);
  const { updateCampaign, createCampaign } = useCampaign(selectedBrand?.id);

  const parseCampaignInfo = useMemo(() => {
    try {
      const cleanedInfo = campaignInfo.replace(/^"|"$/g, '').replace(/\\n/g, '\n');
      const lines = cleanedInfo.split('\n');
      const data: { [key: string]: string } = {};
      lines.forEach((line: string) => {
        const [key, ...valueParts] = line.split(':');
        const value = valueParts.join(':').trim();
        if (key && value) {
          data[key.trim().replace('- ', '')] = value;
        }
      });
      return data;
    } catch (error) {
      console.error('Error parsing campaign info:', error);
      return {};
    }
  }, [campaignInfo]);

  useEffect(() => {
    if (latestCampaign) {
      const newEditedData: EditedData = {
        id: latestCampaign.id,
        name: latestCampaign.name,
        brandName: selectedBrand?.name || '',
        productName: selectedProduct?.name || '',
        target_audience: latestCampaign.target_audience,
        insight: latestCampaign.insight,
        creative_concept: latestCampaign.creative_concept,
        product_id: latestCampaign.product_id,
      };
      setEditedData(newEditedData);
    } else {
      const newEditedData: EditedData = {
        id: '',
        name: parseCampaignInfo['Campaign name'] || '',
        brandName: selectedBrand?.name || parseCampaignInfo['Brand name'] || '',
        productName: selectedProduct?.name || parseCampaignInfo['Associated product'] || '',
        target_audience: parseCampaignInfo['Target audience'] || '',
        insight: parseCampaignInfo['Campaign insight'] || '',
        creative_concept: parseCampaignInfo['Creative concept'] || '',
        product_id: null,
      };
      setEditedData(newEditedData);
    }
  }, [latestCampaign, selectedBrand, selectedProduct, parseCampaignInfo]);

  if (!editedData) {
    return <div>Loading campaign information...</div>;
  }

  return (
    <span className="campaign-info">
      <HoverCard>
        <HoverCardTrigger asChild>
          <span className="campaign-name cursor-pointer">
            {editedData.name || 'Unnamed Campaign'}
          </span>
        </HoverCardTrigger>
        <HoverCardContent className="campaign-hover-content w-80">
          <div className="campaign-details">
            <h3 className="text-lg font-semibold mb-2">Campaign Details</h3>
            <p><strong>Brand:</strong> {editedData.brandName || 'N/A'}</p>
            <p><strong>Product:</strong> {editedData.productName || 'N/A'}</p>
            <p><strong>Target Audience:</strong> {editedData.target_audience || 'N/A'}</p>
            <p><strong>Insight:</strong> {editedData.insight || 'N/A'}</p>
            <p><strong>Creative Concept:</strong> {editedData.creative_concept || 'N/A'}</p>
          </div>
        </HoverCardContent>
      </HoverCard>
      {' was used to process your request. '}
      <span 
        className="text-blue-500 hover:underline cursor-pointer"
        onClick={onSettingsClick} // Use the prop instead of navigate
      >
        Settings
      </span>
    </span>
  );
};

export default CampaignInfo;