import React from 'react';
import { Product } from '../hooks/useProduct';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Upload, X } from 'lucide-react';

interface ProductFormProps {
  product: Product;
  onUpdate: (updatedFields: Partial<Product>) => void;
  onDelete: () => Promise<void>;
}

export function ProductForm({ product, onUpdate, onDelete }: ProductFormProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onUpdate({ [name]: value });
  };

  const handleImageUpload = (index: number, file: File) => {
    const imageUrl = URL.createObjectURL(file);
    onUpdate({ [`image_url${index}`]: imageUrl });
    // TODO: Handle actual file upload to server and replace imageUrl with server URL
  };

  const handleImageRemove = (index: number) => {
    onUpdate({ [`image_url${index}`]: undefined });
    // TODO: Handle image removal on the server if necessary
  };

  const renderImageUpload = (index: number) => {
    const imageUrl = product[`image_url${index}` as keyof Product];
    return (
      <div key={index} className="image-upload-item relative">
        {imageUrl ? (
          <div className="image-preview">
            <img src={imageUrl as string} alt={`Product ${index}`} className="product-image w-24 h-24 object-cover rounded" />
            <Button
              variant="destructive"
              size="icon"
              className="absolute top-0 right-0 bg-red-500 p-1 rounded-full"
              onClick={() => handleImageRemove(index)}
            >
              <X size={16} />
            </Button>
          </div>
        ) : (
          <label className="flex flex-col items-center justify-center w-24 h-24 border-2 border-dashed border-gray-300 rounded cursor-pointer">
            <Upload size={24} />
            <span>Upload</span>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  handleImageUpload(index, file);
                }
              }}
              className="hidden"
            />
          </label>
        )}
      </div>
    );
  };

  return (
    <form className="product-form space-y-4 mt-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Product Name</label>
        <Input
          id="name"
          name="name"
          value={product.name}
          onChange={handleChange}
          className="mt-1"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Product Images</label>
        <div className="image-upload-container flex space-x-4 mt-1">
          {[1, 2, 3].map(index => renderImageUpload(index))}
        </div>
      </div>
      <Button 
        variant="destructive" 
        onClick={onDelete}
        className="mt-4"
      >
        Delete Product
      </Button>
    </form>
  );
}