import React from 'react';
import { X as CloseIcon, Check } from 'lucide-react';
import '../styles/PricingPage.css';
import { Button } from "./ui/button";  // Import the Button component

interface PricingPageProps {
  onClose: () => void;
}

const PricingPage: React.FC<PricingPageProps> = ({ onClose }) => {
  return (
    <div className="pricing-page">
      <div className="pricing-header">
        <button className="close-button" onClick={onClose}>
          <CloseIcon size={24} />
        </button>
      </div>
      <h1 className="pricing-title">Upgrade your plan</h1>
      <div className="pricing-content">
        <div className="pricing-cards">
          <div className="pricing-card featured">
            <div className="featured-label">Recommended for starters</div>
            <h2>Basic</h2>
            <p className="price">$9<span>/month</span></p>
            <ul className="features">
              <li><Check size={16} /> Higher limit of AI generations</li>
              <li><Check size={16} /> Train model on your products</li>
              <li><Check size={16} /> Up to 5 trained products</li>
              <li><Check size={16} /> Chat support</li>
            </ul>
            <Button className="select-plan-button">Select Plan</Button>
          </div>

          <div className="pricing-card">
            <h2>Pro</h2>
            <p className="price">$29<span>/month</span></p>
            <ul className="features">
              <li><Check size={16} /> Higher limit of AI generations</li>
              <li><Check size={16} /> Up to 10 brends</li>
              <li><Check size={16} /> Up to 20 trained products</li>
              <li><Check size={16} /> Call support</li>
            </ul>
            <Button className="select-plan-button">Select Plan</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;