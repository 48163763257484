import React from 'react';
import '../styles/LoadingAnimations.css';

const CreativeLoadingAnimation: React.FC = () => (
  <div className="creative-loading-container">
    <div className="creative-loading-animation">
      {[...Array(2)].map((_, i) => (
        <div key={i} className="loading-square" style={{ '--i': i } as React.CSSProperties}></div>
      ))}
    </div>
    <div className="loading-text">Generating image...</div>
  </div>
);

export default CreativeLoadingAnimation;