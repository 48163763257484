import React from 'react';
import { Download as DownloadIcon, Image as ImageIcon } from '@mui/icons-material';
import CreativeLoadingAnimation from './CreativeLoadingAnimation';

interface ImageContentProps {
  isGeneratingImage: boolean;
  generatedImageUrl: string | null;
  imageLoaded: boolean;
  showDownloadButton: boolean;
  handleDownload: () => void;
  setImageLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImageContent: React.FC<ImageContentProps> = ({
  isGeneratingImage,
  generatedImageUrl,
  imageLoaded,
  showDownloadButton,
  handleDownload,
  setImageLoaded,
}) => {
  return (
    <>
      {isGeneratingImage ? (
        <CreativeLoadingAnimation />
      ) : generatedImageUrl ? (
        <div className={`image-frame-wrapper ${imageLoaded ? 'loaded' : ''}`}>
          <div className="image-frame">
            <img 
              key={generatedImageUrl}
              src={generatedImageUrl} 
              alt="Generated" 
              onLoad={() => {
                setImageLoaded(true);
                console.log('Image loaded:', generatedImageUrl);
              }}
              onError={(e) => {
                console.error('Error loading image:', e);
              }}
            />
            {showDownloadButton && (
              <button className="download-button" onClick={handleDownload}>
                <span>Download</span>
                <DownloadIcon />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="empty-image-container flex flex-col items-center justify-center text-gray-400">
          <ImageIcon style={{ fontSize: 48, marginBottom: '1rem' }} />
          <p>Your generated image will appear here</p>
        </div>
      )}
    </>
  );
};

export default ImageContent;