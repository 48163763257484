import React from 'react';
import { Campaign } from '../hooks/useCampaign';
import { Product } from '../hooks/useProduct';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";

interface CampaignFormProps {
  campaign: Campaign;
  products: Product[];
  onUpdate: (campaignId: string, updatedFields: Partial<Campaign>) => Promise<Campaign | null>;
  onDelete: () => Promise<void>;
}

export function CampaignForm({ campaign, products, onUpdate, onDelete }: CampaignFormProps) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    onUpdate(campaign.id, { [name]: value });
  };

  return (
    <form className="space-y-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Campaign Name</label>
        <Input
          type="text"
          name="name"
          value={campaign.name}
          onChange={handleInputChange}
          className="mt-1"
        />
      </div>
      <div>
        <label htmlFor="target_audience" className="block text-sm font-medium text-gray-700">Target Audience</label>
        <Input
          type="text"
          name="target_audience"
          value={campaign.target_audience}
          onChange={handleInputChange}
          className="mt-1"
        />
      </div>
      <div>
        <label htmlFor="insight" className="block text-sm font-medium text-gray-700">Insight</label>
        <Input
          type="text"
          name="insight"
          value={campaign.insight}
          onChange={handleInputChange}
          className="mt-1"
        />
      </div>
      <div>
        <label htmlFor="creative_concept" className="block text-sm font-medium text-gray-700">Creative Concept</label>
        <Input
          type="text"
          name="creative_concept"
          value={campaign.creative_concept}
          onChange={handleInputChange}
          className="mt-1"
        />
      </div>
      <div>
        <label htmlFor="product_id" className="block text-sm font-medium text-gray-700">Product</label>
        <Select
          value={campaign.product_id || ''}
          onValueChange={(value) => onUpdate(campaign.id, { product_id: value })}
        >
          <SelectTrigger className="mt-1">
            <SelectValue placeholder="Select a product" />
          </SelectTrigger>
          <SelectContent>
            {products.map((product) => (
              <SelectItem key={product.id} value={product.id}>{product.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <Button type="button" variant="destructive" onClick={onDelete}>
        Delete Campaign
      </Button>
    </form>
  );
}