// src/components/forms/BrandForm.tsx
import React from 'react';
import { Input } from "./ui/input";
import { Brand } from '../hooks/useBrand';
import { Button } from "./ui/button";

interface BrandFormProps {
  brand: Brand;
  onUpdate: (updatedFields: Partial<Brand>) => void;
}

const BrandForm: React.FC<BrandFormProps> = ({ brand, onUpdate }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (['name', 'website', 'color1', 'color2', 'color3'].includes(name)) {
      onUpdate({ [name]: value });
    }
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const logoUrl = URL.createObjectURL(file);
      onUpdate({ ...brand, logo_url: logoUrl });
      // TODO: Handle actual file upload to server and replace logoUrl with server URL
    }
  };

  const handleRemoveLogo = () => {
    onUpdate({ ...brand, logo_url: undefined });
    // TODO: Handle logo removal on the server if necessary
  };

  return (
    <div className="brand-form space-y-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Brand Name</label>
        <Input 
          id="name" 
          name="name" 
          value={brand.name} 
          onChange={handleChange}
          className="mt-1" 
        />
      </div>
      <div>
        <label htmlFor="website" className="block text-sm font-medium text-gray-700">Website</label>
        <Input 
          id="website" 
          name="website" 
          value={brand.website || ''} 
          onChange={handleChange}
          placeholder="https://www.example.com" 
          className="mt-1" 
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Brand Colors</label>
        <div className="color-inputs mt-1">
          {['color1', 'color2', 'color3'].map((colorKey) => (
            <div key={colorKey} className="color-input-wrapper flex items-center space-x-2 mb-2">
              <div 
                className="color-preview w-6 h-6 rounded-full border"
                style={{ backgroundColor: brand[colorKey as keyof Brand] || '#000000' }}
              />
              <Input 
                type="color" 
                name={colorKey} 
                value={brand[colorKey as keyof Brand] || '#000000'} 
                onChange={handleChange}
              />
            </div>
          ))}
        </div>
      </div>
      <div>
        <label htmlFor="logo_url" className="block text-sm font-medium text-gray-700">Brand Logo</label>
        <div className="logo-upload-container mt-1">
          {brand.logo_url ? (
            <div className="logo-preview relative">
              <img 
                src={brand.logo_url} 
                alt="Brand Logo" 
                className="brand-logo w-24 h-24 object-cover rounded" 
              />
              <Button
                variant="destructive"
                size="icon"
                className="absolute top-0 right-0 bg-red-500 p-1 rounded-full"
                onClick={handleRemoveLogo}
              >
                &times;
              </Button>
            </div>
          ) : (
            <label className="flex flex-col items-center justify-center w-24 h-24 border-2 border-dashed border-gray-300 rounded cursor-pointer">
              <span>Upload Logo</span>
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                className="hidden"
              />
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandForm;