// src/components/SettingsPage.tsx
import React, { useState, useEffect, forwardRef } from 'react';
import Header from './Header';
import { Business, Person, Lock, History, ChevronLeft, DiamondOutlined } from '@mui/icons-material';
import '../styles/SettingsPage.css';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import PricingPage from './PricingPage';
import { useAuth0 } from '@auth0/auth0-react';
import CustomAlertDialog from './ui/CustomAlertDialog';
import { useBrand, Brand } from '../hooks/useBrand';
import { ProductForm } from './productForm';
import { CampaignForm } from './campaignForm';
import { useProduct, Product } from '../hooks/useProduct';
import { useCampaign, Campaign } from '../hooks/useCampaign';
import BrandForm from './brandForm';
import { useNavigate } from 'react-router-dom';

interface SettingsPageProps {
  isAuthenticated: boolean;
  localUser: any;
  loginWithRedirect: () => void;
  logout: () => void;
  onSettingsClick: () => void;
  onClose: () => void;
  onPricingClick: () => void;
  updateUserName: (name: string) => Promise<void>;
  updateUserNick: (nick: string) => Promise<void>;
}

const menuItems = [
  { id: 'brands', icon: <Business />, label: 'Brands' },
  { id: 'account', icon: <Person />, label: 'Account' },
  { id: 'security', icon: <Lock />, label: 'Security' },
  { id: 'history', icon: <History />, label: 'History' },
];

const SettingsPage = forwardRef<HTMLDivElement, SettingsPageProps>(({
  isAuthenticated,
  localUser,
  loginWithRedirect,
  logout,
  onSettingsClick,
  onClose,
  onPricingClick,
  updateUserName,
  updateUserNick
}, ref) => {
  const { brands, selectedBrand, editedBrand, loading: brandsLoading, error: brandsError, fetchBrands, updateBrand, setSelectedBrand, createBrand, saveChanges: saveBrandChanges, isDirty: brandIsDirty } = useBrand(localUser);
  const { products, selectedProduct, editedProduct, loading: productsLoading, error: productsError, fetchProducts, createProduct, updateProduct, deleteProduct, saveProductChanges, selectProduct, isDirty: productIsDirty } = useProduct(selectedBrand?.id);
  const { campaigns, selectedCampaign, editedCampaign, loading: campaignsLoading, error: campaignsError, fetchCampaigns, createCampaign, updateCampaign, deleteCampaign, saveCampaignChanges, selectCampaign, isDirty: campaignIsDirty } = useCampaign(selectedBrand?.id);
  
  const [selectedMenu, setSelectedMenu] = useState<string>('brands');
  const [selectedSection, setSelectedSection] = useState<string>('details');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [newNick, setNewNick] = useState<string>(localUser?.nick || localUser?.name || '');
  const { user, getAccessTokenSilently } = useAuth0();
  
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState(false);
  const [pendingAction, setPendingAction] = useState<() => void>(() => {});
  const navigate = useNavigate();

  useEffect(() => {
    if (localUser && localUser.id) {
      fetchBrands();
    }
  }, [localUser, fetchBrands]);

  useEffect(() => {
    if (selectedBrand) {
      fetchProducts();
      fetchCampaigns();
    }
  }, [selectedBrand, fetchProducts, fetchCampaigns]);

  useEffect(() => {
    setHasUnsavedChanges(brandIsDirty || productIsDirty || campaignIsDirty);
  }, [brandIsDirty, productIsDirty, campaignIsDirty]);

  const handleSaveAllChanges = async () => {
    try {
      let updatedData = [];

      if (brandIsDirty && editedBrand) {
        const brandData = await saveBrandChanges();
        if (brandData) updatedData.push({ type: 'Brand', data: brandData });
      }

      if (productIsDirty && editedProduct) {
        const productData = await saveProductChanges();
        if (productData) updatedData.push({ type: 'Product', data: productData });
      }

      if (campaignIsDirty && editedCampaign) {
        const campaignData = await saveCampaignChanges();
        if (campaignData) updatedData.push({ type: 'Campaign', data: campaignData });
      }

      if (updatedData.length > 0) {
        console.log('Changes saved successfully:', updatedData);
        setShowSuccessAlert(true);
        setHasUnsavedChanges(false);
      } else {
        console.log('No changes to save');
        setErrorMessage('No changes to save');
        setShowErrorAlert(true);
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      setErrorMessage(error instanceof Error ? error.message : 'An unknown error occurred while saving changes.');
      setShowErrorAlert(true);
    }
  };

  const handleCreateNewBrand = async () => {
    try {
      const newBrand = await createBrand();
      setSelectedBrand(newBrand);
    } catch (error) {
      console.error('Error creating new brand:', error);
      setErrorMessage('An error occurred while creating a new brand.');
      setShowErrorAlert(true);
    }
  };

  const handleCreateNewProduct = async () => {
    if (!selectedBrand) return;
    try {
      await createProduct({ name: 'New Product' });
      fetchProducts();
    } catch (error) {
      console.error('Error creating new product:', error);
      setErrorMessage('Failed to create a new product');
      setShowErrorAlert(true);
    }
  };

  const handleCreateNewCampaign = async () => {
    if (!selectedBrand) return;
    try {
      await createCampaign({ 
        name: 'New Campaign', 
        target_audience: '',
        insight: '',
        creative_concept: '',
        product_id: null
      });
      fetchCampaigns();
    } catch (error) {
      console.error('Error creating new campaign:', error);
      setErrorMessage('Failed to create a new campaign');
      setShowErrorAlert(true);
    }
  };

  const handleMenuChange = (menuId: string) => {
    if (hasUnsavedChanges) {
      setPendingAction(() => () => setSelectedMenu(menuId));
      setShowUnsavedChangesAlert(true);
    } else {
      setSelectedMenu(menuId);
    }
  };

  const renderBrandsContent = () => {
    if (brandsLoading) return <div>Loading brands...</div>;
    if (brandsError) return <div className="error-message">{brandsError}</div>;

    return (
      <div className="content-section">
        <div className="settings-header flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Brand Settings</h2>
          <Button onClick={handleSaveAllChanges} disabled={!hasUnsavedChanges}>
            Save All Changes
          </Button>
        </div>

        <Select
          value={selectedBrand?.id || ''}
          onValueChange={(value) => setSelectedBrand(brands.find(b => b.id === value) || null)}
        >
          <SelectTrigger className="w-full mb-4">
            <SelectValue placeholder="Select a brand" />
          </SelectTrigger>
          <SelectContent>
            {brands.map((brand) => (
              <SelectItem key={brand.id} value={brand.id}>{brand.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Button onClick={handleCreateNewBrand} className="mb-4">Create New Brand</Button>

        {selectedBrand && (
          <div className="brand-sections">
            <div className="flex space-x-2 mb-4">
              <Button 
                variant={selectedSection === 'details' ? 'default' : 'outline'}
                onClick={() => setSelectedSection('details')}
              >
                Brand Details
              </Button>
              <Button 
                variant={selectedSection === 'products' ? 'default' : 'outline'}
                onClick={() => setSelectedSection('products')}
              >
                Products
              </Button>
              <Button 
                variant={selectedSection === 'campaigns' ? 'default' : 'outline'}
                onClick={() => setSelectedSection('campaigns')}
              >
                Campaigns
              </Button>
            </div>

            {selectedSection === 'details' && (
              <BrandForm
                brand={editedBrand || selectedBrand}
                onUpdate={(updatedFields: Partial<Brand>) => {
                  updateBrand(updatedFields);
                }}
              />
            )}

            {selectedSection === 'products' && renderProductsContent()}

            {selectedSection === 'campaigns' && renderCampaignsContent()}
          </div>
        )}
      </div>
    );
  };

  const renderProductsContent = () => {
    return (
      <div>
        <h3 className="text-lg font-semibold mb-2">Products</h3>
        {productsLoading ? (
          <div>Loading products...</div>
        ) : productsError ? (
          <div className="error-message">{productsError}</div>
        ) : (
          <>
            <Select
              value={selectedProduct?.id || ''}
              onValueChange={(value) => selectProduct(value)}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a product" />
              </SelectTrigger>
              <SelectContent>
                {products.map(product => (
                  <SelectItem key={product.id} value={product.id}>{product.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
            {selectedProduct && (
              <ProductForm
                product={editedProduct || selectedProduct}
                onUpdate={updateProduct}
                onDelete={() => deleteProduct(selectedProduct.id)}
              />
            )}
            <Button onClick={handleCreateNewProduct}>Add New Product</Button>
          </>
        )}
      </div>
    );
  };

  const renderCampaignsContent = () => {
    return (
      <div>
        <h3 className="text-lg font-semibold mb-2">Campaigns</h3>
        {campaignsLoading ? (
          <div>Loading campaigns...</div>
        ) : campaignsError ? (
          <div className="error-message">{campaignsError}</div>
        ) : (
          <>
            <Select
              value={selectedCampaign?.id || ''}
              onValueChange={(value) => selectCampaign(value)}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a campaign" />
              </SelectTrigger>
              <SelectContent>
                {campaigns.map(campaign => (
                  <SelectItem key={campaign.id} value={campaign.id}>{campaign.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
            {selectedCampaign && (
              <CampaignForm
                campaign={editedCampaign || selectedCampaign}
                products={products}
                onUpdate={async (campaignId: string, updatedFields: Partial<Campaign>) => {
                  try {
                    const updatedCampaign = await updateCampaign(campaignId, updatedFields);
                    if (updatedCampaign) {
                      setHasUnsavedChanges(true);
                    }
                    return updatedCampaign;
                  } catch (error) {
                    console.error('Error updating campaign:', error);
                    setErrorMessage('Failed to update campaign.');
                    setShowErrorAlert(true);
                    return null;
                  }
                }}
                onDelete={() => deleteCampaign(selectedCampaign.id)}
              />
            )}
            <Button onClick={handleCreateNewCampaign}>Add New Campaign</Button>
          </>
        )}
      </div>
    );
  };

    const renderAccountContent = () => {
      return (
        <div className="content-section">
          <div className="settings-header flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold">Account Settings</h2>
            <Button onClick={handleSaveAllChanges} disabled={!hasUnsavedChanges}>
              Save Changes
            </Button>
          </div>

          <div className="account-details space-y-4">
            <div className="form-group">
              <label htmlFor="userNick" className="block text-sm font-medium mb-1">How should we call you?</label>
              <Input
                id="userNick"
                value={newNick}
                onChange={(e) => {
                  setNewNick(e.target.value);
                  setHasUnsavedChanges(true);
                }}
                className="w-full"
              />
            </div>

            <div className="current-plan">
              <p className="text-sm font-medium mb-1">Current Plan:</p>
              <p className="text-lg font-semibold">
                {localUser?.plan?.charAt(0).toUpperCase() + localUser?.plan?.slice(1) || 'Free'}
              </p>
            </div>

            <Button 
              onClick={onPricingClick}
              className="w-full"
            >
              <DiamondOutlined className="mr-2" />
              Upgrade Plan
            </Button>
          </div>
        </div>
      );
    };

    const renderContent = () => {
      switch (selectedMenu) {
        case 'brands':
          return renderBrandsContent();
        case 'account':
          return renderAccountContent();
        // Implement other cases like 'security', 'history' as needed
        default:
          return null;
      }
    };

    if (showPricing) {
      return <PricingPage onClose={() => setShowPricing(false)} />;
    }

    return (
      <div className="settings-page min-h-screen bg-gray-100" ref={ref}>
        <Header 
          page="settings"
          onBackClick={() => navigate('/chat')}
          isAuthenticated={isAuthenticated}
          user={localUser}
          loginWithRedirect={loginWithRedirect}
          logout={logout}
          onSettingsClick={onSettingsClick}
          onPricingClick={onPricingClick}
        />
        <div className="settings-container flex">
          <div className="settings-sidebar w-64 bg-white p-4 shadow-md">
            <nav className="space-y-2">
              {menuItems.map((item) => (
                <Button
                  key={item.id}
                  variant={selectedMenu === item.id ? 'default' : 'ghost'}
                  className={`w-full justify-start ${selectedMenu === item.id ? 'bg-gray-200' : ''}`}
                  onClick={() => setSelectedMenu(item.id)}
                >
                  {item.icon}
                  <span className="ml-2">{item.label}</span>
                </Button>
              ))}
            </nav>
          </div>
          <div className="settings-main flex-1 p-6">
            {renderContent()}
          </div>
        </div>
        <CustomAlertDialog
          isOpen={showSuccessAlert}
          onOpenChange={setShowSuccessAlert}
          title="Success"
          description="All changes have been saved successfully."
          confirmText="OK"
          onConfirm={() => setShowSuccessAlert(false)}
        />
        <CustomAlertDialog
          isOpen={showErrorAlert}
          onOpenChange={setShowErrorAlert}
          title="Error"
          description={errorMessage}
          confirmText="OK"
          onConfirm={() => setShowErrorAlert(false)}
        />
        <CustomAlertDialog
          isOpen={showUnsavedChangesAlert}
          onOpenChange={setShowUnsavedChangesAlert}
          title="Unsaved Changes"
          description="You have unsaved changes. Do you want to save them before leaving?"
          confirmText="Save"
          cancelText="Discard"
          onConfirm={() => {
            handleSaveAllChanges();
            pendingAction();
            setShowUnsavedChangesAlert(false);
          }}
          onCancel={() => {
            pendingAction();
            setShowUnsavedChangesAlert(false);
            setHasUnsavedChanges(false);
          }}
        />
      </div>
    );
  });

export default SettingsPage;