// src/hooks/useProduct.ts
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export interface Product {
  id: string;
  name: string;
  image_url1?: string;
  image_url2?: string;
  image_url3?: string;
}

export function useProduct(brandId: string | undefined) {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [editedProduct, setEditedProduct] = useState<Product | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchProducts = useCallback(async () => {
    if (!brandId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/brands/${brandId}/products`);
      setProducts(response.data);
      // Select the latest product by default
      if (response.data.length > 0) {
        const latestProduct = response.data[response.data.length - 1];
        setSelectedProduct(latestProduct);
        setEditedProduct(latestProduct);
      }
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  }, [brandId]);

  const createProduct = useCallback(async (newProduct: Omit<Product, 'id'>): Promise<Product> => {
    if (!brandId) throw new Error('No brand selected');
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`/api/brands/${brandId}/products`, newProduct);
      setProducts(prevProducts => [...prevProducts, response.data]);
      return response.data;
    } catch (err) {
      console.error('Error creating product:', err);
      setError('Failed to create product');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [brandId]);

  const updateProduct = useCallback((updatedFields: Partial<Product>) => {
    setEditedProduct(prev => {
      if (!prev) return prev;
      const updated = { ...prev, ...updatedFields };
      setIsDirty(JSON.stringify(updated) !== JSON.stringify(selectedProduct));
      return updated;
    });
  }, [selectedProduct]);

  const saveProductChanges = async () => {
    if (!isDirty || !editedProduct || !selectedProduct) {
      console.log('No changes to save for product');
      return null;
    }

    try {
      console.log('Sending product update:', editedProduct);
      const response = await axios.put(`/api/products/${selectedProduct.id}`, editedProduct);
      console.log('Server response:', response.data);
      setSelectedProduct(response.data);
      setProducts(prevProducts => 
        prevProducts.map(p => p.id === response.data.id ? response.data : p)
      );
      setEditedProduct(response.data);
      setIsDirty(false);
      return response.data;
    } catch (error) {
      console.error('Error saving product:', error);
      throw error;
    }
  };

  const deleteProduct = useCallback(async (productId: string): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      await axios.delete(`/api/products/${productId}`);
      setProducts(prevProducts => prevProducts.filter(p => p.id !== productId));
    } catch (err) {
      console.error('Error deleting product:', err);
      setError('Failed to delete product');
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const selectProduct = useCallback((productId: string | null) => {
    const product = productId ? products.find(p => p.id === productId) || null : null; // Ensure product is null if not found
    setSelectedProduct(product);
    setEditedProduct(product);
    setIsDirty(false);
  }, [products]);

  useEffect(() => {
    if (brandId) {
      fetchProducts();
    } else {
      setProducts([]);
    }
  }, [brandId, fetchProducts]);

  return { 
    products, 
    selectedProduct,
    editedProduct,
    loading, 
    error, 
    fetchProducts, 
    createProduct, 
    updateProduct, 
    deleteProduct, 
    saveProductChanges,
    selectProduct,
    isDirty
  };
}