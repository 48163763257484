import React, { useState } from 'react';
import { ChevronLeft as ChevronLeftIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import '../styles/Header.css';
import appLogo from '../assets/adout2logo.png'; // Update the path to the new location

type Page = 'home' | 'chat' | 'settings';

interface HeaderProps {
  page: Page;
  onBackClick: () => void;
  isAuthenticated: boolean;
  user: any; // This should now be localUser
  loginWithRedirect: () => void;
  logout: () => void;
  onSettingsClick: () => void;
  onPricingClick: () => void;  // Keep this prop, but we won't use it in the UI
}

const Header: React.FC<HeaderProps> = ({ 
  page, 
  onBackClick, 
  isAuthenticated, 
  user, // This should now be localUser
  loginWithRedirect, 
  logout,
  onSettingsClick,
  // onPricingClick is still in the props, but we won't use it here
}) => {
  const [isLoginPromptVisible, setIsLoginPromptVisible] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);

  const handleProfileClick = () => {
    setIsLoginPromptVisible(!isLoginPromptVisible);
  };

  const handleImageError = () => {
    setImageLoadError(true);
  };

  const renderProfileImage = () => {
    if (isAuthenticated && user && user.picture && !imageLoadError) {
      return (
        <img 
          src={user.picture} 
          alt="Profile" 
          className="profile-image" 
          onError={handleImageError}
        />
      );
    } else {
      return <AccountCircleIcon />;
    }
  };

  return (
    <header className="app-header">
      <div className="logo-container">
        {page === 'home' ? (
          <>
            <img src={appLogo} alt="Logo" className="app-logo" />
            <h1 className="adout-text">adout</h1>
          </>
        ) : (
          <button className="back-button" onClick={onBackClick}>
            <ChevronLeftIcon />
          </button>
        )}
      </div>
      <div className="profile-container" onClick={handleProfileClick}>
        {renderProfileImage()}
      </div>
      {isLoginPromptVisible && (
        <div className="login-prompt">
          {!isAuthenticated ? (
            <button className="modal-button" onClick={() => loginWithRedirect()}>Log In</button>
          ) : (
            <div className="login-prompt-menu">
              <button className="modal-button" onClick={onSettingsClick}>Settings</button>
              <button className="modal-button" onClick={() => logout()}>Log out</button>
            </div>
          )}
        </div>
      )}
      {/* Removed the Pricing button from here */}
    </header>
  );
};

export default Header;