import React from 'react';
import '../styles/LoadingAnimations.css';

const PulsatingCircles: React.FC = () => (
  <div className="pulsating-circles">
    {[...Array(3)].map((_, i) => (
      <div key={i} className="pulsating-circle"></div>
    ))}
  </div>
);

export default PulsatingCircles;