// src/hooks/useBrand.ts
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export interface Brand {
  id: string;
  name: string;
  logo_url?: string | null; // {{ edit_1 }}
  color1?: string;
  color2?: string;
  color3?: string;
  website?: string;
  created_at?: string;
  user_id?: string;
}

interface User {
  id: string;
  // Add other user properties as needed
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function useBrand(user: User | null) {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [editedBrand, setEditedBrand] = useState<Brand | null>(null);

  const fetchBrands = useCallback(async (retries = 3): Promise<void> => {
    if (!user || !user.id) {
      console.log('No user, skipping brand fetch');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching brands...');
      const response = await axios.get(`/api/brands/user/${user.id}`);
      console.log('Brands API response:', response.data);
      if (Array.isArray(response.data)) {
        setBrands(response.data);
        if (response.data.length > 0 && !selectedBrand) {
          setSelectedBrand(response.data[0]);
        }
      } else {
        console.error('Unexpected response format:', response.data);
        setError('Unexpected response format from server');
      }
    } catch (err) {
      console.error('Error fetching brands:', err);
      if (axios.isAxiosError(err) && err.response?.status === 429 && retries > 0) {
        console.log(`Rate limited. Retrying in ${4 - retries} seconds...`);
        await delay((4 - retries) * 1000);
        return fetchBrands(retries - 1);
      }
      if (axios.isAxiosError(err)) {
        console.error('Axios error details:', err.response?.data);
        setError(`Failed to fetch brands: ${err.response?.data?.message || err.message}`);
      } else {
        setError('An unknown error occurred while fetching brands');
      }
      setBrands([]);
    } finally {
      setLoading(false);
    }
  }, [user, selectedBrand]);

  const createBrand = useCallback(async (): Promise<Brand> => {
    setLoading(true);
    setError(null);
    try {
      if (!user) throw new Error('User is not defined'); // {{ edit_1 }}
      const response = await axios.post('/api/brands', { name: 'New Brand', userId: user.id });
      console.log('Brand created:', response.data);
      setBrands(prevBrands => [...prevBrands, response.data]);
      setSelectedBrand(response.data);
      return response.data;
    } catch (err) {
      console.error('Error creating brand:', err);
      if (axios.isAxiosError(err)) {
        setError(`Failed to create brand: ${err.response?.data?.message || err.message}`);
      } else {
        setError('An unknown error occurred while creating the brand');
      }
      throw err;
    } finally {
      setLoading(false);
    }
  }, [user?.id]); // {{ edit_1 }}

  const updateBrand = (updatedFields: Partial<Brand>) => {
    setEditedBrand(prev => {
      if (!prev && !selectedBrand) return null;
      const base = prev || selectedBrand;
      if (!base) return null;

      const updated: Brand = {
        ...base,
        ...updatedFields,
        id: base.id // Ensure id is always present
      };

      setIsDirty(JSON.stringify(updated) !== JSON.stringify(selectedBrand));
      return updated;
    });
  };

  const saveChanges = async () => {
    if (!isDirty || !editedBrand || !selectedBrand) return;

    try {
      const changedFields = Object.keys(editedBrand).reduce((acc, key) => {
        if (editedBrand[key as keyof Brand] !== selectedBrand[key as keyof Brand] && editedBrand[key as keyof Brand] !== null) {
          acc[key as keyof Brand] = editedBrand[key as keyof Brand] as string; // Ensure type is string
        }
        return acc;
      }, {} as Partial<Brand>);

      if (Object.keys(changedFields).length === 0) {
        console.log('No changes to save');
        return;
      }

      console.log('Sending update data:', JSON.stringify(changedFields, null, 2));
      const response = await axios.put(`/api/brands/${selectedBrand.id}`, changedFields);
      console.log('Server response:', response.data);
      setSelectedBrand(response.data);
      setBrands(prevBrands => 
        prevBrands.map(b => b.id === response.data.id ? response.data : b)
      );
      setEditedBrand(null);
      setIsDirty(false);
      return response.data;
    } catch (error) {
      console.error('Error saving brand:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', error.response?.data);
      }
      throw error;
    }
  };

  useEffect(() => {
    if (user && user.id) {
      fetchBrands();
    }
  }, [user?.id]); // {{ edit_1 }}

  return { 
    brands, 
    selectedBrand, 
    loading, 
    error, 
    fetchBrands, 
    createBrand, 
    updateBrand, 
    saveChanges, 
    isDirty, 
    setSelectedBrand,
    editedBrand
  };
}