import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import { Button } from "./ui/button";
import { Copy } from 'lucide-react';

interface AIResponseProps {
  content: string;
  isToolSummary?: boolean;
}

const AIResponse: React.FC<AIResponseProps> = ({ content, isToolSummary = false }) => {
  const filteredContent = content.trim();

  if (!filteredContent || filteredContent === "No tools were used in generating this response.") {
    return null;
  }

  const HeadingRenderer = ({ level, children, ...props }: any) => {
    const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
    return <HeadingTag className="mt-4 mb-2 font-semibold font-merriweather text-blue-400" {...props}>{children}</HeadingTag>;
  };

  const ListItemRenderer = ({ children, ...props }: any) => {
    return <li className="mb-1 font-inter" {...props}>{children}</li>;
  };

  const renderers = {
    h1: (props: any) => <HeadingRenderer level={1} {...props} />,
    h2: (props: any) => <HeadingRenderer level={2} {...props} />,
    h3: (props: any) => <HeadingRenderer level={3} {...props} />,
    h4: (props: any) => <HeadingRenderer level={4} {...props} />,
    h5: (props: any) => <HeadingRenderer level={5} {...props} />,
    h6: (props: any) => <HeadingRenderer level={6} {...props} />,
    li: ListItemRenderer,
    p: ({ node, ...props }: any) => <p className="mb-2 font-inter" {...props} />,
    ul: ({ node, ...props }: any) => <ul className="list-disc pl-5 mb-2 font-inter" {...props} />,
    ol: ({ node, ...props }: any) => <ol className="list-decimal pl-5 mb-2 font-inter" {...props} />,
    strong: ({ node, ...props }: any) => <strong className="font-bold font-merriweather" {...props} />,
    em: ({ node, ...props }: any) => <em className="italic font-inter" {...props} />,
    blockquote: ({ node, ...props }: any) => <blockquote className="border-l-4 border-blue-500 pl-4 italic my-4 font-merriweather" {...props} />,
    code: ({ node, inline, ...props }: any) => 
      inline 
        ? <code className="bg-gray-800 bg-opacity-70 rounded px-1 py-0.5 font-mono" {...props} />
        : <pre className="bg-gray-800 bg-opacity-70 rounded p-4 overflow-x-auto my-4 font-mono"><code {...props} /></pre>,
    a: ({ node, ...props }: any) => <a className="text-blue-400 hover:underline font-inter" {...props} />,
  };

  return (
    <div className={`ai-response ${isToolSummary ? 'tool-summary' : ''}`}>
      <ReactMarkdown components={renderers}>{filteredContent}</ReactMarkdown>
      <div className="absolute bottom-1 right-1">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className="p-1 h-5 w-5"
                onClick={() => navigator.clipboard.writeText(filteredContent)}
              >
                <Copy className="h-3 w-3" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p className="font-inter text-xs">Copy response</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
};

export default AIResponse;